<template>
    <div class="index" v-if="have_id == true">
        <div class="tips">
            <p v-if="status == 4" style="background: #009fe9">您已申请{{ info.name }}业务，查询进度或有疑问请联系下方微信客服</p>
            <p v-if="status == 5" style="background: #ff5722">
                您申请的{{ info.name }}业务已办理完成，如有疑问请联系微信客服<span v-if="cert !== ''" @click="showCert" style="text-decoration: underline">
                    查看凭证</span
                >
            </p>
            <p v-if="status == 6" style="background: #9a6c13">已为您完成退款，如有疑问请联系微信客服</p>
            <p v-if="status == 7 || status == 8" style="background: #ff976a">您已申请{{ info.name }}业务，正在为您办理，查询进度请联系微信客服</p>
        </div>
        <div class="header-bg">
            <div class="header-title">{{ xm_name }}</div>
            <div class="info-box">
                <div class="enterprise-info">
                    <div class="title"><b> 企业名称： </b> {{ info.company_name }}</div>
                    <div class="title"><b> 企业法人： </b> {{ info.company_faren }}</div>
                    <div class="title" style="border-bottom: 0px"><b>信用代码：</b> {{ info.company_code }}</div>
                </div>
            </div>
            <p style="font-size: 12px; margin-left: 4%; color: white; margin-top: 10px">* 信息来源于国家企业信用信息公示系统，请及时处理</p>
        </div>
        <div class="applyfor-box">
            <div class="applyfor-info">
                <div class="applyfor-head">
                    <div class="applyfor-txt">企业服务办理</div>
                </div>
                <div class="zf-box-list" v-for="(item, index) in xiangmu" @click="changeActive(index)">
                    <div class="box" :class="{ active_class: active == index }">
                        <div class="name">{{ index }}</div>
                        <div class="price">￥{{ item }}</div>
                        <div class="select" :class="{ active_select: active == index }"></div>
                    </div>
                </div>
                <div class="choose-box-pay">
                    <div class="choose-item" @click="changePayType('wechat')" :class="{ pay_type: pay_type == 'wechat' }">
                        <van-icon name="wechat-pay" color="#28C445" size="39" />
                        <div>
                            微信支付
                            <br />
                            <b v-if="defaultpay == 'wechat'" style="font-size: 12px; color: #ff4800">推荐</b>
                        </div>
                    </div>
                    <div class="choose-item" @click="changePayType('alipay')" :class="{ pay_type: pay_type == 'alipay' }">
                        <van-icon name="alipay" color="#009FE9" size="39" />
                        <div>
                            支付宝
                            <br />
                            <b v-if="defaultpay == 'alipay'" style="font-size: 12px; color: #ff4800">推荐</b>
                        </div>
                    </div>
                </div>
                <div ref="pay_box"></div>
                <div class="showbox">
                    <van-checkbox v-model="checked_agreement" shape="square" icon-size="13px"></van-checkbox>
                    <span>
                        阅读并同意
                        <span @click="show_business = true" class="red_span">《服务协议》</span>
                        且授权办理企业服务
                    </span>
                    <van-popup v-model:show="show_business" closeable class="popup_box">
                        <div class="popup_title">服务协议</div>
                        <div class="popup_content">
                            <p v-html="xieyi"></p>
                        </div>
                    </van-popup>
                </div>
            </div>
        </div>
        <div class="abnormal-box">
            <div class="abnormal-info">
                <div class="notice">
                    <van-icon name="volume" color="#E21E1E" size="15" />
                    <div class="notice-txt">及时检查营业执照经营状态，有异常尽快解除，以免被处罚！</div>
                </div>
                <div class="yiju_err"><van-icon name="question" color="#E21E1E" size="15" /> &nbsp;法规依据</div>
                <div class="list-head">
                    <div class="th" style="width: 330px">《市场主体登记管理条例实施细则》第七十条</div>
                </div>
                <div class="item">
                    <div class="td" style="width: 330px">
                        根据第十一章第七十条规定，“市场主体未按照法律、行政法规规定的期限公示或者报送年度报告的，
                        由登记机关列入经营异常名录，可以<b>处1万元以下罚款</b>。”
                    </div>
                </div>
                <div class="item">
                    <div class="td" style="width: 330px">
                        市场主体未报送公示往年年度报告由登记机关列入经营异常名录，可能处罚。提交异常修复可以移除经营异常，若不再经营建议注销！
                    </div>
                </div>

                <div class="abnormal-bottom">(正规财务公司为您代办，省心省钱，请继续往下操作)</div>
            </div>
        </div>
        <div class="applyfor-box">
            <div class="applyfor-info">
                <div class="content-box houguo" style="padding: 12px 8px">
                    <h2>列入异常经营名录后果</h2>
                    <div class="content">
                        <ol>
                            <li>企业被列入“经营异常名录”的信息将同步向全社会公示的</li>
                            <li>影响企业商誉及对外形象</li>
                            <li>影响公司法定代表人及董监高等人员个人信誉及形象</li>
                            <li>企业在政府采购、工程招投标、国有土地出让、授予荣誉称号等事项上，将被予以限制或者禁入</li>
                            <li>被列入经营异常名录的企业，满3年仍未履行公示义务的，将被列入严重违法企业名单，并通过企业信用信息公示系统向社会公示</li>
                            <li>被列入严重违法企业名单的企业的法定代表人、负责人，3年内不得担任其他企业的法定代表人、负责人</li>
                        </ol>
                        <p>
                            除了上述影响外，同时，我们应当关注被列入“经营异常名录”的企业属于《失信企业协同监管和联合惩戒合作备忘录》中的联合惩戒对象，随着国家对于协同监管和联合惩戒的相关配套措施进一步完善落地，以后对于列入“经营异常名录”企业的监管和惩戒将会越来越严，对企业及企业家的影响也会越来越大。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="txt-basis">
            *特别申明:
            {{
                xm_name
            }}仅在获得客户的授权后为客户进行工商、税务等事宜代办。我们非国家工商税务行政管理部门，仅为提高客户工商办理效率，降低客户工商办理难度提供有偿服务，所收费用为服务费并可开具发票，请知悉。
            <b style="color: #ff4800">线上线下两个渠道直接向监管部门报送是免费办理</b>
        </div>
        <div class="foot-box">
            <div class="foot-info">
                <div class="foot-txt">{{ xm_name }}由{{ company_name }}独家运营，旨在为中小企业提供各类工商、资质代办服务。</div>
                <div class="font-font">
                    <div class="foot-cellphone">
                        客服电话: <a :href="'tel:' + kefu_phone">{{ kefu_phone }}</a>
                    </div>
                    <div class="foot-cellphone text-right">
                        投诉电话: <a :href="`tel:` + kefu_phone">{{ kefu_phone }}</a>
                    </div>
                </div>
            </div>
            <div style="height: 69px"></div>
        </div>
        <div class="g-fixedOperate-foot">
            <van-button @click="toPay" :loading="loading_btn" class="btn_blue">立即办理</van-button>
        </div>
        <div class="kefudianhua">
            <van-icon name="service-o" size="28px" @click="openNew" color="#116bff" />

            <div @click="openNew" class="wxkf" style="text-decoration: none; color: red">微信客服</div>
            <a :href="`tel:` + kefu_phone" style="text-decoration: none; color: orange">电话<br />客服</a>
        </div>
        <van-popup v-model:show="show_submit" closeable class="popup_box popup_submit">
            <div class="popup_title">请核对单位法人信息方便后期办理</div>
            <van-cell-group inset>
                <van-field v-model="info.company_faren" label="法人姓名" placeholder="请输入法人姓名" />
                <van-field v-model="info.phone" label="法人手机号" placeholder="请输入法人手机号" />
                <van-field v-model="info.id_card" label="身份证号码" placeholder="请输入身份证号码" />
            </van-cell-group>
            <van-button @click="submitInfo" class="sbumit_btn" type="primary">提交信息</van-button>
        </van-popup>
    </div>
    <div class="index no_page_box" v-else>
        <van-empty image="error" description="请通过网址访问" />
    </div>
</template>
<script>
export default {
    name: "index",
    data() {
        return {
            active: 0,
            pay_type: "",
            defaultpay: "", //选择支付方式
            checked_agreement: true,
            show_business: false, //服务协议
            xieyi: "",
            show_submit: false, //提交信息弹窗
            info: {
                company_faren: "",
                phone: "",
                id_card: "",
            },
            xiangmu: {},
            kefu_url: "",
            kefu_phone: "",
            xm_name: "",
            company_name: "",
            status: 0, //订单状态
            loading_btn: false,
            have_id: true, //展示空白页
            cert: [], //查看凭证
        };
    },
    mounted() {
        if (this.$route.params.id == undefined) {
            this.have_id = false;
        } else {
            this.getInfo();
        }
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState == "visible") {
                // this.getInfo();
                if (this.$route.params.id == undefined) {
                    this.have_id = false;
                } else {
                    this.getInfo();
                }
            }
        });
    },
    methods: {
        //切换项目
        changeActive(index) {
            this.active = index;
        },
        //切换支付方式
        changePayType(val) {
            this.pay_type = val;
        },
        //展示查看凭证
        showCert() {
            this.$showImagePreview(this.cert);
        },
        //获取详情
        getInfo() {
            let params = {
                id: this.$route.params.id,
            };
            this.$post("/api/index/info", params).then((res) => {
                if (res.code == 0) {
                    this.$showFailToast(res.msg);
                } else if (res.code == 1) {
                    document.title = res.data.xm_name;
                    this.kefu_phone = res.data.kefu_phone;
                    this.kefu_url = res.data.kefu_url;
                    this.xiangmu = res.data.xiangmu;
                    this.xm_name = res.data.xm_name;
                    this.company_name = res.data.company_name;
                    this.xieyi = res.data.xieyi;
                    this.info = res.data.info;
                    this.defaultpay = res.data.defaultpay;
                    this.pay_type = res.data.defaultpay;
                    this.cert = res.data.info.cert;

                    this.status = res.data.info.status;
                    // this.status = 5;
                    // 'Status 1'  =>  '短信未发送',
                    // 'Status 2'  =>  '短信已发送',
                    // 'Status 3'  =>  '用户已查看',
                    // 'Status 4'  =>  '用户已支付',
                    // 'Status 5'  =>  '业务已办理',
                    // 'Status 6'  =>  '用户已退款',
                    // 'Status 7'  =>  '业务办理中',
                    // 'Status 8'  =>  '办理遇到困难',
                    if (this.status == 4 && res.data.info.faren_info == 0) {
                        this.show_submit = true;
                    }
                }
            });
        },
        //立即办理
        toPay() {
            if (this.active == 0) {
                window.scrollTo({
                    top: 60,
                    behavior: "smooth",
                });
                this.$showFailToast("请选择项目");
            } else if (this.checked_agreement == false) {
                this.$showFailToast("请勾选服务协议");
            } else {
                this.loading_btn = true;
                let params = {
                    id: this.$route.params.id,
                    type: this.active,
                    paytype: this.pay_type,
                };
                this.$post("/api/index/confirm", params)
                    .then((res) => {
                        this.$showSuccessToast(res.msg);
                        if (res.code == 1) {
                            setTimeout(() => {
                                //支付宝支付返回表单，需要把表单单独提交，微信是直接返回网页跳转，所以不用执行提交，执行提交找不到表单 报错
                                if (this.pay_type == "alipay") {
                                    this.$refs.pay_box.innerHTML = res.data;
                                    this.$nextTick(() => {
                                        this.$refs.pay_box.children[0].submit();
                                    });
                                } else {
                                    window.location.href = res.data;
                                }
                                this.loading_btn = false;
                            }, 500);
                        } else {
                            this.loading_btn = false;
                            this.$showFailToast(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$showFailToast("提交异常，请稍后再试");
                        this.loading_btn = false;
                    });
            }
        },
        //提交信息
        submitInfo() {
            if (this.info.company_faren == "") {
                this.$showFailToast("法人姓名不能为空");
            } else if (this.info.phone == "") {
                this.$showFailToast("手机号不能为空");
            } else if (this.info.id_card == undefined) {
                this.$showFailToast("身份证号不能为空");
            } else {
                let params = {
                    id: this.$route.params.id,
                    name: this.info.company_faren,
                    phone: this.info.phone,
                    id_card: this.info.id_card,
                };
                this.$post("/api/index/addinfo", params).then((res) => {
                    this.show_submit = false;
                    this.$showSuccessToast(res.msg);
                });
            }
        },
        //微信客服
        openNew() {
            window.open(this.kefu_url);
        },
    },
};
</script>
<style lang="scss" scoped>
.tips {
    color: #fff;
    font-size: 14px;
    line-height: 26px;
    z-index: 999;
    p {
        padding: 5px 10px;
    }
}
.header-bg {
    background-color: #116bff;
    width: 100%;
    border-radius: 0 0 16px 16px;
    padding-bottom: 16px;

    .header-title {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        padding: 0 16px;
        height: 44px;
        line-height: 44px;
    }

    .endterprise-tit {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        margin: 10px 0;
        text-align: center;
    }

    .info-box {
        padding: 0 14px 0 14px;
    }

    .enterprise-info {
        padding: 12px 10px 12px;
        background-color: #fff;
        border-radius: 8px;
    }

    .title {
        color: #222;
        font-size: 14px;
        font-weight: 400;
        padding: 5px 6px 3px;
        border-bottom: 1px solid #fdf7e9;
        line-height: 180%;
    }

    .title .key {
        font-weight: 700;
        display: inline;
    }

    .title .value {
        display: inline;
    }
}
.applyfor-box {
    margin-top: 12px;
    padding: 0 10px;
    .applyfor-info {
        border-radius: 8px;
        border: 1px solid #428aff;
    }

    .applyfor-head {
        border-radius: 6px 6px 0 0;
        padding: 12px;
        background-color: #116bff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .applyfor-txt {
        color: #fff;
        font-weight: 700;
        font-size: 18px;
    }

    .applyfor-body {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 0.8rem;
        border-bottom: 1px #116bff solid;
        height: 40px;
        line-height: 40px;
        padding-left: 12px;
    }

    .applyfor-conter {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }

    .applyfor-fot {
        display: flex;
        color: #666;
        font-size: 12px;
        margin-top: 8px;
        font-weight: 700;
        flex-direction: column;
        align-items: center;
    }

    .zf-box-list {
        margin: 16px 0;
    }

    .zf-box-list .box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 59px;
        width: 334px;
        margin: 0 auto;
        opacity: 1;
        margin-bottom: -5px;
        padding: 0 25px;
        border-radius: 25px;
        color: #116bff;
        background-color: #bdd6ff;
        border: 2px solid #fff;
    }

    .zf-box-list .box .name {
        font-size: 17px;
        font-weight: 600;
        width: 78px;
        white-space: nowrap;
    }

    .zf-box-list .box .price {
        font-size: 23px;
        font-weight: 500;
        margin-left: 70px;
    }
    .zf-box-list .box .select {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 100%;
        border: 1px solid #116bff;
    }
    .active_class {
        border: 2px solid #116bff !important;
    }
    .active_select {
        background: #116bff !important;
        border: 2px solid #fff !important;
    }
    .choose-box-pay {
        margin-top: 22px;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .choose-box-pay .choose-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: 2px solid #fff;
        box-shadow: 0 0px 9px 3px rgb(0 0 0 / 10%);
        border-radius: 25px;
        padding: 0 10px;
        min-width: 0;
        box-sizing: border-box;
        cursor: pointer;
        flex-wrap: wrap;
        width: 155px;
        height: 64px;
    }

    .choose-box-pay .choose-item:nth-child(2n) {
        margin-left: 14.6px;
    }
    .pay_type {
        border: 2px solid #116bff !important;
    }
    .boxshow {
        padding: 5px 6px;
        font-size: 12px;
        line-height: 18px;
    }
    .showbox {
        padding: 0 15px 10px;
        font-size: 12px;
        display: flex;
        align-items: flex-start;
        line-height: 13px;
    }
    .showbox span {
        margin-left: 5px;
    }
    .red_span {
        color: #ff5722;
    }
    .content-box {
        padding: 10px 16px;
        line-height: 200%;
        font-size: 12px;
        color: #999;
    }
    .houguo h2 {
        color: #da2b15;
        padding: 10px 15px;
        background-color: #fcedea;
        border-radius: 10px;
        margin: 10px auto;
        text-align: center;
        width: 200px;
    }

    .houguo .content ol {
        margin: 0;
    }
    .houguo .content ol li {
        text-indent: 1.5em;
        position: relative; /* 设置相对定位 */
        counter-increment: section; /* 增加计数器的值 */
    }
    .houguo .content ol li::before {
        content: counters(section, ".") "."; /* 显示计数器的值 */
        counter-increment: none; /* 阻止递增 */
        position: absolute; /* 设置绝对定位 */
        left: -2ch; /* 缩进的宽度，这里使用ch单位表示2个字符宽度 */
    }
}
.abnormal-box {
    margin-top: 12px;
    padding: 0 10px;
    .abnormal-info {
        border-radius: 8px;
        padding: 12px;
        border: 1px solid #428aff;
    }

    .notice {
        display: flex;
        white-space: nowrap;
        align-items: center;
    }

    .img {
        width: 14px;
        height: 14px;
    }

    .notice-txt {
        color: #e32929;
        font-weight: 600;
        font-size: 14px;
        margin-left: 6px;
        display: flex;
        align-items: center;
        white-space: break-spaces;
    }

    .yiju_err {
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    .list-head {
        display: flex;
        color: #9a6c13;
        background: #f9cf63;
    }

    .list-head .th {
        font-size: 14px;
        padding: 8px 10px;
        flex-direction: column;
        display: flex;
        align-items: center;
        font-weight: 700;
    }

    .item {
        display: flex;
        background-color: fff;
        padding: 0px 10px;
        border-bottom: 1px #f9cf63 solid;
    }

    .item {
        text-align: left;
    }

    .item .td {
        line-height: 22px;
        color: #353535;
        font-weight: 400;
        font-size: 14px;
        padding: 8px 0;
    }
    .item .td b {
        color: #f44336;
    }

    .abnormal-info .abnormal-bottom {
        font-size: 12px;
        padding: 10px 0 0 0;
        color: #666;
        font-weight: 700;
        text-align: center;
    }
}
.txt-basis {
    font-size: 12px;
    color: #9b9b9b;
    line-height: 22px;
    padding: 0 10px;
    .txt-basis {
        margin-top: 12px;
    }
}
.foot-box {
    width: 100%;
    box-sizing: border-box;
    margin-top: 12px;
    padding: 10px 10px 16px;
    line-height: 160%;
    background-color: #116bff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    .foot-info {
        border-radius: 8px;
        padding: 10px 12px;
        border: 1px solid #c6a844;
        line-height: 150%;
    }

    .foot-txt {
        color: #fff;
        font-weight: 700;
        text-align: left;
        font-size: 13px;
        text-indent: 28px;
        margin-bottom: 8px;
    }

    .font-font {
        display: flex;
    }

    .font-font .foot-cellphone a {
        color: #fff;
    }

    .font-font .text-right {
        text-align: right;
    }

    .font-font .foot-cellphone {
        width: 50%;
        text-align: left;
        font-size: 12px;
        color: #fff;
    }

    .foot-copyright {
        text-align: center;
        font-size: 12px;
        color: #fff;
        margin-top: 9px;
    }
}
.g-fixedOperate-foot {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 888;
    background-color: #fff;
    box-shadow: 0 -3.65px 3.65px 0 rgba(0, 0, 0, 0.05);
    .btn_blue {
        height: 55px;
        line-height: 55px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        width: 345px;
        background: #3e73fe !important;
        opacity: 1;
        border-radius: 10px;
    }
}
.kefudianhua {
    padding: 12px 10px;
    box-sizing: border-box;
    width: 50px;
    position: fixed;
    right: 0px;
    bottom: 90px;
    background: #f0f0f0;
    border-radius: 8px 0 0 8px;
    z-index: 9;
    text-align: center;
    color: #000;
    font-size: 10px;
    text-decoration: none;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    .wxkf {
        font-size: 14px;
    }
    a {
        display: block;
        margin-top: 8px;
    }
}
.popup_box {
    width: 350px;
    height: 530px;
    .popup_title {
        font-size: 16px;
        color: #303133;
        padding: 20px 15px 10px;
    }
    .popup_content {
        height: 465px;
        color: #606266;
        font-size: 12px;
        padding: 10px 15px;
        overflow: auto;
        line-height: 18px;
    }
    .popup_bottom {
        width: 56px;
        height: 32px;
        color: #fff;
        font-size: 12px;
        background-color: #409eff;
        float: right;
        margin: 15px;
        border: none;
    }
}
.popup_submit {
    width: 340px;
    height: 300px;
    border-radius: 8px;
    .van-cell-group {
        border: solid 1px var(--van-cell-border-color);
        margin-top: 20px;
    }
    ::v-deep .van-field__label {
        width: 80px;
    }
    .sbumit_btn {
        width: 320px;
        margin: auto;
        display: block;
        margin-top: 20px;
    }
}
.no_page_box {
    img {
        display: block;
        margin: auto;
        margin-top: 95px;
    }
    div {
        text-align: center;
        margin-top: 20px;
        color: #999;
    }
}
</style>
