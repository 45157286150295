import axios from "axios";
// import router from "../../router";
import { showToast, showLoadingToast, showSuccessToast, showFailToast } from "vant";

const api = axios.create({
    baseURL: process.env.NODE_ENV == "production" ? "/" : "http://adm.h7it.cn/",
    // timeout: 3000,// 请求超时时间 当请求时间超过5秒还未取得结果时 提示用户请求超时
    // headers: { Authorization: sessionStorage.getItem("token") },
});
api.interceptors.request.use(
    (config) => {
        // config.headers.Authorization = sessionStorage.getItem("token");
        // config.headers.token = sessionStorage.getItem("token");
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
api.interceptors.response.use(
    (response) => {
        //状态码
        // 1   -- 成功
        // 0   -- 未登录
        // console.log(response);
        if (response.status === 200) {
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        console.log("error", error);
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                //异常处理break;
                case 403:
                    break;
                case 404:
                    break;
                default:
                    return Promise.reject(error.response);
            }
        }
        return Promise.reject(error);
    }
);

const $get = (url, params) => {
    return new Promise((resolve, reject) => {
        api.get(url, { params: params })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const $post = (url, params, headers) => {
    const header = {
        // 'responseType': 'json'
    };
    const headerss = Object.assign({}, headers, header);
    return new Promise((resolve, reject) => {
        api.post(url, params, { headers: headerss })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export default {
    install: (app) => {
        app.config.globalProperties.$get = $get;
        app.config.globalProperties.$post = $post;
        app.config.globalProperties.$axios = axios;
    },
};
